import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ContactHero from "../components/ContactHero";

export default () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setEmail("info@wimmic.com");
    }, 500);
    return () => {};
  }, []);
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1>Privacy policy</h1>

                <p>
                  WIRELESS INNOVATIVE MMIC S.L. (hereinafter WIMMIC) guarantees
                  the protection of all personal data that is provide through
                  their website https://wimmic.com/ (in forward, the "Website")
                  in compliance with the provisions of Organic Law 15/1999, of
                  December 13, on Data Protection of Personal Data, in Royal
                  Decree 1270/2007, of 21 of December, RGPD and remaining
                  applicable regulations.
                </p>
                <p>
                  Those Users that provide their personal data because they want
                  to make a question through the WIMMIC web contact form, should
                  take into account the following:
                </p>
                <p>
                  By providing your email, you agree to receive a response to
                  your inquiry.
                </p>
                <p>
                  In this form you will be asked for your email in order to
                  answer to that address and your name, in order to properly
                  address your person. This data is not stored in any database,
                  and its purpose is only to respond to queries you may have via
                  email.
                </p>
                <p>
                  You can, at any time, exercise your rights of opposition,
                  access, rectification and cancellation by sending an email to
                  the address <span className="email">{email}</span>, indicating
                  the action requested, which must be accompanied by a photocopy
                  of your D.N.I./N.I.F. or document that proves your identity.
                </p>

                <p> CUSTOMER CARE</p>
                <p>
                  For any kind of doubt, question or suggestion, you can send us
                  an email to <span className="email">{email}</span> or a
                  communication by postal mail to:
                </p>
                <p> WIRELESS INNOVATIVE MMIC S.L.</p>
                <p> Avenida Alcalde José Ramírez Bethencourt, 17</p>
                <p> 35004 – Las Palmas de Gran Canaria</p>
                <p> España</p>
                <p> APPLICABLE LEGISLATION </p>
                <p>
                  These Terms and Conditions shall be governed by Spanish law,
                  without prejudice to the mandatory regulations that may be
                  applicable in each country of the Territory. In any conflict
                  that can not be resolved amicably between WIMMIC and the
                  Client, the competent courts will be those of the city of Las
                  Palmas de Gran Canaria, except for those that establish the
                  mandatory norms that result from application.
                </p>
                {/* COOKIES */}
                <h1>Cookies policy </h1>
                <p>
                  This website, as mostly all websites, use cookies and similar
                  technologies which will be included in term “cookies”
                  hereinafter. Its use is regulated by this Cookies Policy.
                </p>
                <p>What are cookies?</p>
                <p>
                  They are small amounts of information sent through a website
                  and stored in the user’s browser or device, this way website
                  which emits them can access the user’s previous activity.
                  These cookies may be generated by us or third parties.
                </p>
                <p>What kind of cookies WIMMIC use?</p>
                <p>Based on it use:</p>
                <p>
                  Functional Cookies: These cookies allow our websites, products
                  and services to remember choices you make. These cookies can
                  also be used to track if you accept our cookies policy or
                  share our content through social networks.
                </p>
                <p>
                  Analytical Cookies: These cookies allow us to count the number
                  of visitors to our website as well as analyze and measure
                  their experience through statistics. In some cases, we use a
                  third-party to provide this information.{" "}
                </p>
                <p>Based on their length:</p>
                <p>
                  Session Cookies: These expire when the user closes the browser
                  and they then immediately are deleted from the device.
                </p>
                <p>
                  Persistent cookies: Their expiration will depend on when their
                  purpose is fulfilled (for example, to make sure you have
                  consented cookies use) or when they are manually deleted.
                </p>
                <p>Depending on who creates the Cookie:</p>
                <p>
                  First-party cookies: These are the ones that our Web Services
                  send to your device and are exclusively managed by WIMMIC.
                </p>
                <p>
                  Third-party cookies: These are sent by entities from outside
                  WIMMIC and are activated when accessing our website to view
                  videos, click on our listings or when accessing
                  advertisements.
                </p>
                <p>
                  Cookies may generate a unique id, but WIMMIC never combine
                  this id with personal data identifiers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactHero />
    </Layout>
  );
};
